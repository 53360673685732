import React, { useEffect, useState } from "react";
import './styles/App.css';
import './styles/mint.css';
import myNft from './utils/MyNft.json';
import { ethers, providers } from "ethers";
import { Web3Provider } from "@ethersproject/providers";
import Modal from 'react-modal';
import { Link, useSearchParams } from "react-router-dom";
import { WinterCheckout } from '@usewinter/checkout';

import {
    Web3ReactProvider,
    useWeb3React,
    UnsupportedChainIdError
} from "@web3-react/core";

import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";

import {
    URI_AVAILABLE,
    UserRejectedRequestError as UserRejectedRequestErrorWalletConnect
} from "@web3-react/walletconnect-connector";

import { useEagerConnect, useInactiveListener } from "./hooks";

import {
    injected,
    walletconnect,
    walletlink,
} from "./Connector/Connectors";

import { Spinner } from "./Spinner";

/*
// NOTES
   high limit public, 10 presale


// MEGA CONFIGS!!!!
// ##################################################################################################

*/    
    // POP IN THE MERKLE API URI:   NOTE, I HAVE MULTIPLE ACTIVE ONES NOW, SO BE CAREFUL!
    let CONFIGS_MERKLE_API = "https://standard-template-allowlist-api2.netlify.app/.netlify/functions/express/api/"; 

    /*
    ETH: 0.002
    WEI: 2000000000000000
    CHK  2000000000000000
    TEST CONTRACTS: 
    V6: 0x408D872b13BDFE5488fD266f101773De7A305deA
    V7: 0x78829229ddf9D5601756ed4f698039B6e6A5a056
    */

    // MAIN NORMAL STUFF TO DEFINE:
    let CONFIGS_DROPNAME = 'Spiritual Beings';  // used in ALTs and so forth
    let CONFIGS_MINT_PRICE_CALC = 0.5406662; // used for calculating
    let CONFIGS_PROMO_PRICE_CALC = 0.4054997; // used for calculating
    let CONFIGS_CURRENT_PRICE = CONFIGS_MINT_PRICE_CALC;
    const CONFIGS_TOTALSUPPLY_CALC = 11111; // used for calculating -- I think we may need this to calculate SOLD OUT
    let CONFIGS_TOTALSUPPLY_DISPLAY = '11,111'; // used for display purposes only...
    let CONFIGS_NFTS_RESERVED = 663; // I think we may need this to calculate SOLD OUT
    let CONFIGS_WALLET_LIMIT = 200; // High limit public, 10 presale..
    let CONFIGS_TRANSACTION_LIMIT = 10; // testing only! Actual settings tbd.
    const CONFIGS_CONTRACT_ADDRESS = "0xD3e51621b30d6a7F6E951E3E43a110f6b6aC835B";
    let CONFIGS_CHAIN_ID = 1; // Chain ID:  1==MAINNET, 4==RINKEBY, 5==GOERLI, 11155111=SEPOLIA
    let CONFIGS_CHAIN_DESCRIPTION = 'Mainnet'; // used in the ALERT
    let CONFIGS_NETWORK = ''; //SB: blank (mainnet), 'rinkeby.'  'goerli.'  'sepolia.' (w/ period) -- used for link building only
    let CONFIGS_OPENSEA_URL = 'opensea.io'; // make either 'opensea.io' or 'testnets.opensea.io'
    let CONFIGS_NFT_TITLE_SINGULAR = 'SKELETON';
    let CONFIGS_NFT_TITLE_PLURAL = 'SKELETONS';
    let CONFIGS_URLS_TWITTER = 'https://twitter.com/sbeingsNFT';
    let CONFIGS_URLS_INSTAGRAM = 'https://www.instagram.com/spiritualbeingsnft/';
    let CONFIGS_URLS_TIKTOK = 'https://www.tiktok.com/@spiritualbeingsnft?lang=en';
    let CONFIGS_URLS_MEDIUM = 'https://medium.com/@sbeingsNFT';
    let CONFIGS_URLS_YOUTUBE = 'https://www.youtube.com/channel/UC7CY03__YWVPhdhdO47ABaA';
    let CONFIGS_URLS_OPENSEA_COLLECTION = 'https://'+ CONFIGS_OPENSEA_URL +'/collection/spiritualbeingscollection';
    let CONFIGS_URLS_PROJECTWEBSITE = 'https://www.spiritualbeings.io/';
    let CONFIGS_CONTRACT_URL = 'https://' + CONFIGS_NETWORK + 'etherscan.io/address/' + CONFIGS_CONTRACT_ADDRESS;

    // PROMO/CC UPDATE
    let CC_PROMO_CODES = ['savekings'];
    let CC_PURCHASES_ENABLED = 1; // 1 has CCBUYER, 0 hides it all (ETH only)
    let CC_PROJECT_ID = '10431';  // from Winter  usewinter.com
    let CC_PRODUCTION = true;  // false for testing, true for production

    // VARIOUS MESSAGES / STRINGS:
    let CONFIGS_ALLOW_LIST_MESSAGE = 'Sorry, this is an allowlist-only minting period and your wallet is not on the list. Please return for the public mint, and/or chat with a team member if you believe this to be in error.';

    let CONFIGS_BEGIN_MINT_ALERT = 'Working on your purchase now ... please click Okay and wait while we interface with the Ethereum blockchain. Another alert will popup shortly and let you know that your purchase is complete.';

    let CONFIGS_MINT_SUCCESS_ALERT = 'You have succesfully purchased! Your digital collectible(s) should appear in your wallet and on OpenSea shortly. Thanks so much!!';

    // VARIOUS ERRORS FROM SOLIDITY:
    // Fill this object with some terms that would be found from our solidity contract's errors,
    // and then we can write custom responses for the alerts (but keep them all up here):
    let SOLIDITY_ERROR_LIST = {
        1: {
            'error': '[error text snippet from wallet error]',
            'response': '[alert response]'
        },
        2: {
            'error': 'exceed the wallet limit',
            'response': 'Your transaction would exceed the wallet limit.'
        },
        3: {
            'error': 'exceed the max supply',
            'response': 'This drop is sold out and/or you are trying to purchase more than the remaining supply. Check OpenSea for the secondary market.'
        },
        4: {
            'error': 'exceed max supply',
            'response': 'This drop is sold out and/or you are trying to purchase more than the remaining supply. Check OpenSea for the secondary market.'
        },
        5: {
            'error': 'Sale is not active',
            'response': 'The sale has been disabled on the smart contract.'
        },
        6: {
            'error': 'Not enough ether sent',
            'response': 'You sent too little ETH for your purchase. If you feel this error is wrong, drop the team a note.'
        },
        7: {
            'error': 'User denied transaction',
            'response': 'The user has denied the current transaction.'
        },
        8: {
            'error': 'already claimed',
            'response': 'It looks like you have already claimed your allowlist reserves.'
        },
        9: {
            'error': 'insufficient funds',
            'response': 'Insufficient funds. Please add enough ETH to your wallet for this purchase + gas.'
        },
        10: {
            'error': 'are not allowlisted',
            'response': 'Sorry, you are not on the presale list. Please contact the team if you believe this to be an error.'
        }, 
        11: {
            'error': 'exceed the transaction',
            'response': 'Sorry, you cannot purchase that many digital collectibles in a single transaction during this mint.'
        }
    }

    let GENERIC_RESPONSE = 'Transaction canceled. Usually if you see this, it means that you have rejected a transaction. If you feel that this message displayed because of another error, please alert the devs and we will have a look.';

// ##################################################################################################
function floatify(number){
    return parseFloat((number).toFixed(10));
 }

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const connectorsByName = {
    Metamask: injected,
    WalletConnect: walletconnect,
    Coinbase: walletlink,
};

const connectorImagesByName = {
    Metamask: './MetaMask_Fox.svg.png',
    WalletConnect: './walletconnect.png',
    Coinbase: './coinbase-logo-freelogovectors.net_.png',
};

function getErrorMessage(error) {
    if (error instanceof NoEthereumProviderError) {
        return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
    } else if (error instanceof UnsupportedChainIdError) {
        return "You're connected to an unsupported network.";
    } else if (
        error instanceof UserRejectedRequestErrorInjected ||
        error instanceof UserRejectedRequestErrorWalletConnect
    ) {
        return "Please authorize this website to access your Ethereum account.";
    } else {
        console.error(error);
        return "An unknown error occurred. Check the console for more details.";
    }
}

function getLibrary(provider, connector) {
    const library = new Web3Provider(provider);
    library.pollingInterval = 8000;
    return library;
}

export default function() {
    return ( <Web3ReactProvider getLibrary = { getLibrary }><App/></Web3ReactProvider>) };

const App = () => {

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    let [searchParams, setSearchParams] = useSearchParams();

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const context = useWeb3React();
    const {
        connector,
        library,
        chainId,
        account,
        activate,
        deactivate,
        active,
        error
    } = context;

    // handle logic to recognize the connector currently being activated
    const [activatingConnector, setActivatingConnector] = useState();

    useEffect(() => {
        console.log('running')
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, connector]);

    // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
    const triedEager = useEagerConnect();

    // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
    useInactiveListener(!triedEager || !!activatingConnector);

    const [mintCount, setMintCount] = useState(0);
    const [value, setValue] = useState(1);
    const [myNFTs, setMyNFTs] = useState([]);
    const [trackMintCount, setTrackMintCount] = useState(0);
    const [trackAnyMintCount, setTrackAnyMintCount] = useState(0);
    const [isSold, setIsSold] = useState(false);
    const [isLibrary, setIsLibrary] = useState(false);
    const [mintPrice, setMintPrice] = useState(CONFIGS_CURRENT_PRICE); 
  
    // PROMO/CC UPDATE  TRYING NEW...
    const [promoCode, setPromoCode] = useState('');  //PROMONEW
    const [errorMsgPromo, setErrorMsgPromo] = useState(''); //PROMONEW
    const [ccAffiliateFlag, setccAffiliateFlag] = useState(0); //CCBUYER
    const [ccAffiliateRef, setccAffiliateRef] = useState(''); //CCBUYER

    // PROMO/CC UPDATE -- WINTER CC STUFF...
    const [showWinter, setShowWinter] = useState(false);
    window.addEventListener('message', (event) => {
        console.log(`Received message: ${event.data}`);
        if (event.data === 'closeWinterCheckoutModal') {
          setShowWinter(false);
        } 
    });
    
    const toggleWinter = async () => {
        setShowWinter(true)
    };
  
    let tokenId;
    let signer;
    let currentTotalSupply;

    const handleChange = (event) => {
        setValue(event.target.value);
        console.log("Mint amount = " + value);
    };

    // PROMO/CC UPDATE
    useEffect(() => {
        console.log('ue:AFFILIATE FLAG: ' + ccAffiliateFlag);
        return;      
    }, [ccAffiliateFlag]);

    // PROMO/CC UPDATE
    useEffect(() => {
        console.log('ue:AFFILIATE REF: ' + ccAffiliateRef);
        return;      
    }, [ccAffiliateRef]);

    // PROMO/CC UPDATE
    function applyPromoCode() {
        setupEventListener();
        console.log('apc: PROMO CODE: ' + promoCode);
        return;
    }

     // PROMO/CC UPDATE
     function ccBuyerApplyPromoCode(){

        // this is here to apply promo pricing for non-connected people (CC buyers)

        // if called, set this to normal price first...
        CONFIGS_CURRENT_PRICE = CONFIGS_MINT_PRICE_CALC;  
        setMintPrice(CONFIGS_CURRENT_PRICE);   

        let thisItem;

        // then iterate and find cheaper price if possible...
        let wasMatch=0; 
        for (var i = 0; i < CC_PROMO_CODES.length; i++) {
            thisItem = CC_PROMO_CODES[i];
            console.log('xm: THIS ITEM: ' + thisItem);
            if ( thisItem == promoCode) {
                setPromoCode(promoCode.toLowerCase());
                CONFIGS_CURRENT_PRICE = CONFIGS_PROMO_PRICE_CALC;  
                setMintPrice(CONFIGS_CURRENT_PRICE);                
                console.log('xm: code match: ' + thisItem);
                wasMatch=1;
                setccAffiliateFlag(1);
                setErrorMsgPromo("✅ Promo code " + promoCode + " activated. Discount applied!");
            } 
        }
        if (wasMatch==0) { setPromoCode(''); setccAffiliateFlag(0);}

        console.log('xm: PROMO CODE: ' + promoCode);
        return;
    }    

    // PROMO/CC UPDATE
    useEffect(() => {
        if (!promoCode) {
            setErrorMsgPromo("*Discounts applied at mint (if valid).");
        }
        console.log('PROMO CODE: ' + promoCode);
        return;      
    }, [promoCode]);


    const setupLibrary = async() => {

        try {

            if (library) {
                setIsLibrary(true);
            }

            console.log("Setup Library");

        } catch (error) {
            console.log(error);
        }
    }

    const setupEventListener = async() => {

        try {
            const wallet = library;
            signer = wallet.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            // event listener
            connectedContract.on("Mint", (from, tokenId) => {
                setTrackAnyMintCount((trackAnyMintCount) => trackAnyMintCount + 1);
                console.log(from, tokenId.toNumber());
            });

            if (mintCount >= (CONFIGS_TOTALSUPPLY_CALC - CONFIGS_NFTS_RESERVED)) {
                setMintCount(CONFIGS_TOTALSUPPLY_CALC);
                setIsSold(true);
            }
            console.log('mintCount: ' + mintCount);

            console.log('EventListener...'+searchParams.get("ref"));
            let affiliateRef = searchParams.get("ref") || "";

                affiliateRef = affiliateRef.toLowerCase();
                
                // maybe this is an on-page "apply"   ... if so, check...
                if (affiliateRef=='' && promoCode) {
                    affiliateRef=promoCode;
                }

                // But let's see if the affiliate is valid ...
                let chkAffiliate = await connectedContract.affiliateAccounts(affiliateRef);

                // a) Does he/she have a commission fee?
                let theCommission = chkAffiliate.affiliateFee;
                console.log('EventListener Affiliate fee is: ' + theCommission);

                // b) And is he/she activated?
                let isActive = chkAffiliate.affiliateIsActive   
                console.log('EventListener Affiliate activated? : ' + isActive);    

                //[] THEN REPLACE THIS SECTION WITH BELOW:
                if (theCommission && isActive) {
                    CONFIGS_CURRENT_PRICE = CONFIGS_PROMO_PRICE_CALC;  
                    setMintPrice(CONFIGS_CURRENT_PRICE);
                    setccAffiliateFlag(1);
                    setccAffiliateRef(affiliateRef);
                    setPromoCode(affiliateRef.toLowerCase());
                    setErrorMsgPromo("✅ Promo code " + affiliateRef + " activated. Discount applied!");   
                    console.log('el: Affiliate Ref: ' + affiliateRef); 
                    console.log('el: Affiliate Flag...: ' + ccAffiliateFlag); 
                } else {
                    // SECTION  ADDED during CCBUYER changes...
                    CONFIGS_CURRENT_PRICE = CONFIGS_MINT_PRICE_CALC;   
                    setMintPrice(CONFIGS_CURRENT_PRICE);       
                    setccAffiliateFlag(0);
                    setccAffiliateRef('');  
                    setPromoCode();
                    setErrorMsgPromo("🔶 Discounts applied at mint (if valid).");                           
                    console.log('el: MINT PRICE: : ' + mintPrice);    
                }

            if (library) {
                setIsLibrary(true);
            }

            console.log("Setup event listener!");

        } catch (error) {
            console.log(error);
        }
    }

    const askContractToMintNft = async() => {
        
        console.log('ChainID is: '+chainId);
        if (chainId !== CONFIGS_CHAIN_ID) {
            alert("Please connect to " + CONFIGS_CHAIN_DESCRIPTION);
            return;
        }

        try {
            const provider = library;
            const signer = provider.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            let isAffiliate = false;
            console.log(searchParams);
            console.log(searchParams.get("ref"));
            let affiliateRef = searchParams.get("ref") || "";

            // if (!affiliateRef) { affiliateRef=promoCode;}
            // promo code takes precedence over ref in url..
            if (promoCode) { affiliateRef = promoCode; }

            // Cast to lowercase because we don't want case to be a problem...
            // Affiliates can share refs like Jim, jim, JIM, etc. and it'll still
            // work because we are enforcing all lowercase on the contract side.
            affiliateRef = affiliateRef.toLowerCase();
            console.log('Affiliate ref is:'+affiliateRef);

            if(affiliateRef){

                // ok looks like an affiliate sale.
                isAffiliate = true;
              
                // But let's see if the affiliate is valid ...
                let chkAffiliate = await connectedContract.affiliateAccounts(affiliateRef);

                // a) Does he/she have a commission fee?
                let theCommission = chkAffiliate.affiliateFee;
                console.log('Affiliate fee is: ' + theCommission);

                // b) And is he/she activated?
                let isActive = chkAffiliate.affiliateIsActive   
                console.log('Affiliate activated? : ' + isActive);

                // If eitehr of those tests fail, we will not pass this along to the mint function
                // as an affiliate sale because it would throw a needless error. Instead, we will
                // pass it as just a non-commission sale. Kthx.
                if (theCommission==0 || isActive==false) { 
                    affiliateRef=''; isAffiliate = false; 
                    console.log('Mint passed to contract, but note that the user has likely come to a URL of an invalid affiliate because no fee structure was estabished on the smart contract and/or the affiliate was not activated on the contract.');
                }

                // PROMO NEW
                if (theCommission && isActive) {
                    isAffiliate = true;
                    CONFIGS_CURRENT_PRICE = CONFIGS_PROMO_PRICE_CALC;  
                    setMintPrice(CONFIGS_CURRENT_PRICE);
                    setccAffiliateRef(affiliateRef);
                    setccAffiliateFlag(1);
                }

            }

            console.log("Public Minting: Popping wallet open now.")
            
            // note: floatify This fixes Javascript's floating point math problem here...
            // see: https://stackoverflow.com/questions/588004/is-floating-point-math-broken
            let totalPrice = floatify(CONFIGS_CURRENT_PRICE * value);
            console.log('Total Price: ' + value + ' NFTs @ ' + CONFIGS_CURRENT_PRICE + ' ETH == ' + totalPrice + ' ETH');

            let nftTxn = await connectedContract.mint(value, isAffiliate, affiliateRef, {
                value: ethers.utils.parseUnits(totalPrice.toString())
            });

            console.log("Public Minting: Please wait...")

            alert(CONFIGS_BEGIN_MINT_ALERT);

            await nftTxn.wait();

            alert(CONFIGS_MINT_SUCCESS_ALERT);

            console.log("Mint tx: https://" + CONFIGS_NETWORK + "etherscan.io/tx/" + nftTxn.hash);
            setTrackMintCount(trackMintCount + 1);

        } catch (e) {
            console.log(e)
            var error = e.toString().split(',');
            var rawErrorMessage = e.toString();
            let numSolidityErrors = Object.keys(SOLIDITY_ERROR_LIST).length;
            let errorFound = 0;

            // some canned responses from above:
            for (let i = 1; i <= numSolidityErrors; i++) {
                var targetString = SOLIDITY_ERROR_LIST[i].error;
                if (rawErrorMessage.search(targetString) > 1) {
                    let theMessage = SOLIDITY_ERROR_LIST[i].response;
                    errorFound++;
                    alert(theMessage);
                    console.log(theMessage);
                }
            }

            // or if no error was found yet:
            if (!errorFound) {

                alert(GENERIC_RESPONSE);
                console.log(rawErrorMessage);

            }

        }

    }

    const allowlistMint = async() => {
        console.log(chainId);
        if (chainId !== CONFIGS_CHAIN_ID) {
            alert("Please connect to " + CONFIGS_CHAIN_DESCRIPTION);
            return;
        }

        try {
            const provider = library;
            const signer = provider.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            const walletAdd = await signer.getAddress();
            console.log(String(walletAdd));
            const requestOptions = {
                "referrerPolicy": "strict-origin-when-cross-origin",
                "body": null,
                "method": "GET",
                "mode": "cors",
                "credentials": "omit"
            }

            let res = await fetch(CONFIGS_MERKLE_API + String(walletAdd), requestOptions);
            console.log(res);

            let merkleObject = await res.json();
            console.log(merkleObject);

            if (!merkleObject.valid) {
                alert(CONFIGS_ALLOW_LIST_MESSAGE);
                return;
            }

            console.log("Allowlist Mint: Popping wallet now to pay gas.");

            // note: floatify This fixes Javascript's floating point math problem here...
            // see: https://stackoverflow.com/questions/588004/is-floating-point-math-broken
            let totalPrice = floatify(CONFIGS_CURRENT_PRICE * value);
            console.log('Allowlist Ttl Price: ' + value + ' NFTs @ ' + CONFIGS_CURRENT_PRICE + ' ETH == ' + totalPrice + ' ETH');

            let nftTxn = await connectedContract.allowlistMint(merkleObject.proof, value, {
                value: ethers.utils.parseUnits(totalPrice.toString())
            });
            
            console.log("Allowlist Minting: Please wait...")

            alert(CONFIGS_BEGIN_MINT_ALERT);

            await nftTxn.wait();

            alert(CONFIGS_MINT_SUCCESS_ALERT);

            console.log("Minted, see transaction: https://" + CONFIGS_NETWORK + "etherscan.io/tx/" + nftTxn.hash);
            setTrackMintCount(trackMintCount + 1);

        } catch (e) {

            console.log(e)
            var error = e.toString().split(',');
            var rawErrorMessage = e.toString();
            let numSolidityErrors = Object.keys(SOLIDITY_ERROR_LIST).length;
            let errorFound = 0;

            // some canned responses from above:
            for (let i = 1; i <= numSolidityErrors; i++) {
                var targetString = SOLIDITY_ERROR_LIST[i].error;
                if (rawErrorMessage.search(targetString) > 1) {
                    let theMessage = SOLIDITY_ERROR_LIST[i].response;
                    errorFound++;
                    alert(theMessage);
                    console.log(theMessage);
                }
            }

            // or if no error was found yet:
            if (!errorFound) {

                alert(GENERIC_RESPONSE);
                console.log(rawErrorMessage);

            }

        }

    }

    const getTotalNFTsMintedSoFar = async() => {

        try {

            const provider = library;
            const signer = provider.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            console.log("Getting Mint Count")
            let mint_count = await connectedContract.totalSupply();
            console.log(ethers.utils.formatUnits(mint_count, 0));
            setMintCount(ethers.utils.formatUnits(mint_count, 0));

            console.log(`Set mint count is ${mint_count}`);

            console.log('Getting Minted NFTs');
            const userBalance = await connectedContract.balanceOf(account);
            console.log(userBalance);

            if (mint_count >= (CONFIGS_TOTALSUPPLY_CALC - CONFIGS_NFTS_RESERVED)) {
                setIsSold(true);
            }

        } catch (error) {

            console.log(error)

        }
    }

    const getTotalOwnerNFTs = async() => {
        console.log('Show collection functionality removed.');
    }

    const onDisconnect = async() => {
        console.log("Killing the wallet connection", library);
        setIsLibrary(false);
        // disconnect wallet
        deactivate();
        console.log('disconnect acct: '+account);
        console.log('disconnect lib: '+library);
        console.log('disconnect conn: '+connector);
        CONFIGS_CURRENT_PRICE = CONFIGS_MINT_PRICE_CALC;  
        setMintPrice(CONFIGS_CURRENT_PRICE);
        console.log('disconnect mint price: '+CONFIGS_CURRENT_PRICE);
        
    }

    useEffect(() => {
        setupLibrary();
    });

    useEffect(() => {
        if (library) { setupEventListener() };
    }, [isLibrary]);

    useEffect(() => {
        console.log(isLibrary);
        console.log(trackAnyMintCount);
        if (library) { getTotalNFTsMintedSoFar() }
    }, [isLibrary, trackAnyMintCount]);

    useEffect(() => {
        if (library) { getTotalOwnerNFTs() }
    }, [isLibrary, trackMintCount]);



    return (

        <div className="App">

            <div className="header" >

                <div className="headerLeft">

                    <a className="logowords" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_PROJECTWEBSITE }><img src="droplogo.png" className="thelogo" alt={CONFIGS_DROPNAME+' Home'} title={CONFIGS_DROPNAME+' Home'} /></a> 

                </div>

                <div className="headerRight">

                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_TWITTER }><img src="logo-twitter.png" alt={'Follow ' + CONFIGS_DROPNAME + ' on Twitter'} title={'Follow ' + CONFIGS_DROPNAME + ' on Twitter'} /></a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_YOUTUBE }><img src="logo-youtube.png" alt={'Follow ' + CONFIGS_DROPNAME + ' on Youtube'} title={'Follow ' + CONFIGS_DROPNAME + ' on Youtube'} /></a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_INSTAGRAM }><img src="logo-instagram.png" alt={'Follow ' + CONFIGS_DROPNAME + ' on Instagram'} title={'Follow ' + CONFIGS_DROPNAME + ' on Instagram'} /></a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_TIKTOK }><img src="logo-tiktok.png" alt={'Catch ' + CONFIGS_DROPNAME + ' on TikTok'} title={'Catch ' + CONFIGS_DROPNAME + ' on TikTok'} /></a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_MEDIUM }><img src="logo-medium.png" alt={'Catch ' + CONFIGS_DROPNAME + ' on Medium'} title={'Catch ' + CONFIGS_DROPNAME + ' on Medium'} /></a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_OPENSEA_COLLECTION }><img src="logo-opensea.png" alt={'View the ' + CONFIGS_DROPNAME + ' collection on OpenSea'} title={'View the ' + CONFIGS_DROPNAME + ' collection on OpenSea'} /></a> 
                    <a className="pageButton marginright" target="_blank" rel="noreferrer" href={ CONFIGS_CONTRACT_URL }><img src="logo-etherscan.png" alt={'View the ' + CONFIGS_DROPNAME + ' verified contract on Etherscan'} title={'View the ' + CONFIGS_DROPNAME + ' verified contract on Etherscan'} /></a> 
                </div>

            </div>

            <div className="clear"> </div>

            <div className="mintBoxWrapper" >

                <div className="mintBoxLeft" >

                    <div className="allButMobile">
                        <img src="featured.gif" alt={CONFIGS_DROPNAME} />
                    </div>

                </div>

                <div className= "mintBoxRight" >

                    <img className= "mobileOnly" src="featured.gif" alt="" />

                    <h2>Purchase Spiritual Beings</h2>
                    <p className="mintBoxRightMessage">11,111 Digital Collectibles with Purpose</p>
                    {/* <p className="supplyInfo">{CONFIGS_TOTALSUPPLY_DISPLAY} {CONFIGS_NFT_TITLE_PLURAL} @ { mintPrice } ETH</p> */}

                    <div id="DAppArea" >

                        {
                            (active === false) ? ( 

                                <div id="prepare">

                                    <h3>Ethereum Buyers:</h3>

                                    <button onClick = { openModal } className="btn-primary pageButton" id="connectButton" type="button" >Connect Ethereum Wallet</button> 

                                    <hr></hr>

                                    <h3>Credit Card Buyers:</h3>

                                    <WinterCheckout 
                                        projectId={CC_PROJECT_ID} 
                                        production={CC_PRODUCTION} 
                                        showModal={showWinter} 
                                        extraMintParams={ JSON.parse('{ "isAffiliate" : ' + ccAffiliateFlag + ', "affiliateRef" : ' + ( (promoCode) ? ('"' + promoCode + '"') : ('""') ) + '}') }
                                        priceFunctionParams={ JSON.parse('{ "affiliateRef" : ' + ( (promoCode) ? ('"' + promoCode + '"') : ('""') ) + '}') }
                                        appearance={{leftBackgroundColor: "#131317", rightBackgroundColor: "#22222d", buttonTextColor: "black", buttonColor: "#f59e0c",
                                        primaryTextColor: "white", secondaryTextColor: "#85868a", fontFamily: "Montserrat,sans-serif", buttonAndInputBoxShadow: "0 3px 6px 1px rgba(217, 119, 6, 0.2)", buttonAndInputFocusBoxShadow: "0 3px 6px 1px rgba(217, 119, 6, 0.8)", quantityButtonPlusMinusSvgFilter: "invert(100%) sepia(100%) saturate(1%) hue-rotate(135deg) brightness(105%) contrast(101%)",
                                        inputBackgroundColor: "#131317", mintingClipLoaderColor: "white", borderColor: "rgba(245,158,11)"}}
                                        />
                                
                                    <button className="cta-button connect-wallet-button" onClick={toggleWinter}> Purchase Using Credit Card </button>

                                    <hr></hr>

                                    <h3>Got a Promo Code?</h3>
                                    <input id="promoArea" type="text" value = { promoCode } onChange = {e => setPromoCode(e.target.value.toLowerCase()) } placeholder = "enter code" /> <button onClick={ ccBuyerApplyPromoCode }> Apply Promo Code</button>
                                    <p>{ errorMsgPromo }</p>

                                    <Modal isOpen = { modalIsOpen } onAfterOpen = { afterOpenModal } onRequestClose = { closeModal } style = { customStyles } contentLabel = "Wallet Connection"> 

                                        <h2 ref = { (_subtitle) => (subtitle = _subtitle) }>Choose Wallet Provider</h2>
                                                                                
                                        {
                                            Object.keys(connectorsByName).map(name => {
                                                const currentConnector = connectorsByName[name];
                                                const activating = currentConnector === activatingConnector;
                                                const connected = currentConnector === connector;

                                                return (
                                                    <button className="providerChoices" key={ name } onClick = { () => {
                                                            setActivatingConnector(currentConnector);
                                                            activate(connectorsByName[name]);
                                                            closeModal(); }}>
                                                        <div style = { { position: "absolute", top: "0", left: "0", height: "100%", display: "flex", alignItems: "center", color: "black", margin: "0 0 0 1rem" } }> { activating && ( <Spinner color={"black"} style={{height:"25%", marginLeft: "-1rem" } } /> ) }
                                                        </div> 
                                                        <img className='logo-wallet' src={ connectorImagesByName[name] } alt="" /> 
                                                        <span className="walletName" > { name } </span>
                                                    </button>
                                                );

                                            })
                                        }

                                    </Modal>

                                </div>

                            ):(

                                (isSold === false) ? ( 

                                    <div>

                                        { /* <p className="sub-text" > Minted:{ ' ' }<span id="currentMintCount">{ mintCount }</span> { ' ' } / { ' ' } { CONFIGS_TOTALSUPPLY_DISPLAY }</p> */ }

                                        <p className="quantityQuestion">Select Quantity:</p>

                                        <div className="theSlider">
                                            <input id="sliderVal"  className="slider"  onChange = { handleChange }  max={ CONFIGS_TRANSACTION_LIMIT } min="1" type="range"  value = { value } /> 
                                        </div>                                        

                                        { /* PRESALE MINT BUTTON  
                                        <button onClick = { allowlistMint } className="btn-custom btn-primary pageButton" type="button" id="theMintButton" >PRESALE Mint { value } { (value < 2) ? CONFIGS_NFT_TITLE_SINGULAR: CONFIGS_NFT_TITLE_PLURAL
                                        } </button> */ }
                                        

                                        { /* PUBLIC SALE MINT BUTTON.. DON'T FORGET TO ACTIVATE PUBLIC SALE IN THE CONTRACT!!!!!!!!!  */ }
                                        <button onClick={ askContractToMintNft } className="btn-custom btn-primary pageButton" type="button" id="theMintButton">Purchase { value } { ( value < 2 ) ? CONFIGS_NFT_TITLE_SINGULAR : CONFIGS_NFT_TITLE_PLURAL }
                                        </button> 
                                        

                                        <div id="totalPrice" >Total Price:&nbsp;&nbsp;<b>{ (CONFIGS_CURRENT_PRICE * value).toFixed(4) }</b> ETH + gas </div>
                                        <p className="promoMessage1">{errorMsgPromo}</p>

                                        { (!ccAffiliateFlag) ? ( <div><p className="promoMessage2"><a href="##" onClick={onDisconnect} >Got a promo code?</a></p></div> ) : (<></>) }

                                        <div id="connected">

                                            <button onClick = { onDisconnect } className="btn btn-primary pageButton" id="disconnectButton" type="button">Disconnect <span class='smaller fixcaps'> 0x{ String(account).slice(2, 5) + "..." + String(account).slice(-4) }</span></button>

                                            <p className="buttonMessage">After purchasing, go to <a target="_blank" href={ 'http://'+ CONFIGS_OPENSEA_URL + '/' + account }>your wallet on OpenSea</a> to view.</p>

                                        </div> 

                                    </div>

                                ) : (

                                    <div id="totalPrice">COLLECTION IS SOLD OUT<br /><a href={CONFIGS_URLS_OPENSEA_COLLECTION}>Have a look on OpenSea</a> to grab a rare on the secondary market.</div>

                                )

                            )
                        }

                        <p><span className="soldFont" id="amountSold"> </span></p>

                    </div>

                </div>

            </div>

            <div className="clear" > </div>

        </div>

    );

};
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

const POLLING_INTERVAL = 12000;
const RPC_URLS = {
    1: "https://mainnet.infura.io/v3/7320d7a7567b4826a2e244e860b95bff",
    4: "https://rinkeby.infura.io/v3/7320d7a7567b4826a2e244e860b95bff",
    5: "https://goerli.infura.io/v3/7320d7a7567b4826a2e244e860b95bff",
    11155111: "https://sepolia.infura.io/v3/7320d7a7567b4826a2e244e860b95bff"
};

// CHANGE ALL TO:  1==MAINNET, 4==RINKEBY, 5=GOERLI, 11155111=SEPOLIA  (2 changes under WalletConnect!)

// METAMASK
export const injected = new InjectedConnector({
    supportedChainIds: [1]
});

// WALLET CONNECT
export const walletconnect = new WalletConnectConnector({
    rpc: { 1: RPC_URLS[1] },
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
    pollingInterval: POLLING_INTERVAL
});

// COINBASE
export const walletlink = new WalletLinkConnector({
    url: RPC_URLS[1],
    appName: "web3-react-nft"
});